






$message-margin: $spacing-24 0 $spacing-24 0;
$title-padding: 0 0 $spacing-10 0;


$error: $color-regal-one;
$success: $color-secondary-three-dark;
$info: $color-royal-three;
$info-xs: $color-gray;
$help: $color-royal-three-dark;
$warning: $color-secondary-one-dark-2;


$xsmall-body-text-size--sm: 12px;
$xsmall-body-text-size--md: $xsmall-body-text-size--sm;
$xsmall-body-text-size--lg: $xsmall-body-text-size--sm;
$xsmall-body-line-height--sm: $line-height-16;
$xsmall-body-line-height--md: $line-height-16;
$xsmall-body-line-height--lg: $line-height-16;

$small-body-text-size--sm: 14px;
$small-body-text-size--md: 14px;
$small-body-text-size--lg: 16px;
$small-body-line-height--sm: $line-height-20;
$small-body-line-height--md: $line-height-20;
$small-body-line-height--lg: $line-height-20;
$small-title-text-size: 14px;
$small-title-line-height: $line-height-20;

$medium-body-text-size--sm: $small-body-text-size--sm;
$medium-body-text-size--md: 16px;
$medium-body-text-size--lg: 16px;
$medium-body-line-height--sm: $small-body-line-height--sm;
$medium-body-line-height--md: $line-height-24;
$medium-body-line-height--lg: $line-height-24;
$medium-title-text-size--sm: $small-title-text-size;
$medium-title-text-size--md: 16px;
$medium-title-text-size--lg: 16px;
$medium-title-line-height--sm: $small-title-line-height;
$medium-title-line-height--md: $line-height-24;
$medium-title-line-height--lg: $line-height-24;

$large-body-text-size--sm: $medium-body-text-size--sm;
$large-body-text-size--md: $medium-body-text-size--md;
$large-body-text-size--lg: 16px;
$large-body-line-height--sm: $medium-body-line-height--sm;
$large-body-line-height--md: $medium-body-line-height--md;
$large-body-line-height--lg: $line-height-24;
$large-title-text-size--sm: $medium-title-text-size--sm;
$large-title-text-size--md: $medium-title-text-size--md;
$large-title-text-size--lg: $spacing-20 !important;
$large-title-line-height--sm: $medium-title-line-height--sm;
$large-title-line-height--md: $medium-title-line-height--md;
$large-title-line-height--lg: 28px;



$xsmall-icon-size: $spacing-16;
$xsmall-icon-box-size--sm: 0 0 44px;
$xsmall-icon-box-size--md: 0 0 48px;
$xsmall-icon-box-size--lg: 0 0 auto;

$small-icon-size: $spacing-20;
$small-icon-box-size--sm: 0 0 52px;
$small-icon-box-size--md: 0 0 56px;
$small-icon-box-size--lg: 0 0 auto;

$medium-icon-size: $spacing-24;
$medium-icon-box-size--sm: $small-icon-box-size--sm;
$medium-icon-box-size--md: 0 0 64px;
$medium-icon-box-size--lg: 0 0 auto;

$large-icon-size: 32px;
$large-icon-box-size--sm: $medium-icon-box-size--sm;
$large-icon-box-size--md: $medium-icon-box-size--md;
$large-icon-box-size--lg: 0 0 auto;


$xsmall-text-line-padding: 0 0 $spacing-8 0;
$small-text-line-padding: 0 0 $spacing-10 0;
$medium-text-line-padding: 0 0 $spacing-12 0;
$large-text-line-padding: 0 0 $spacing-12 0;

$small-title-padding: 0 0 $spacing-8 0;
$medium-title-padding: 0 0 $spacing-12 0;
$large-title-padding: 0 0 $spacing-16 0;

$small-close-icon-margin--sm: 14px 6px 0 0;
$small-close-icon-margin--md: 14px 6px 0 0;
$small-close-icon-margin--lg: 14px $spacing-16 0 0;

$medium-close-icon-margin--sm: $small-close-icon-margin--sm;
$medium-close-icon-margin--md: 18px 6px 0 0;
$medium-close-icon-margin--lg: 18px 14px 0 0;

$large-close-icon-margin--sm: $medium-close-icon-margin--sm;
$large-close-icon-margin--md: $medium-close-icon-margin--md;
$large-close-icon-margin--lg: $spacing-20 14px 0 0;

$xsmall-icon-padding--sm: $spacing-4 20px 0 $spacing-12;
$xsmall-icon-padding--md: $xsmall-icon-padding--sm;
$xsmall-icon-padding--lg: $xsmall-icon-padding--sm;

$small-icon-padding--sm: $spacing-16 34px 0 $spacing-16;
$small-icon-padding--md: $small-icon-padding--sm;
$small-icon-padding--lg: $small-icon-padding--sm;

$medium-icon-padding--sm: $small-icon-padding--sm;
$medium-icon-padding--md: $spacing-24 42px 0 $spacing-20;
$medium-icon-padding--lg: $medium-icon-padding--md;

$large-icon-padding--sm: $medium-icon-padding--sm;
$large-icon-padding--md: $medium-icon-padding--md;
$large-icon-padding--lg: $spacing-24 50px 0 $spacing-24;

$content-padding--xs: 0 $spacing-12 $spacing-12 0;
$content-padding--sm: $spacing-16 $spacing-16 $spacing-16 $spacing-2;
$content-padding--md: $spacing-20 $spacing-20 $spacing-20 $spacing-2;
$content-padding--lg: $spacing-24 $spacing-24 $spacing-24 $spacing-2;