@import '/apps/wt/webtools/sparta_build_server/tmp/9C7439D6/artifacts/components/utilities/global/sparta-style-utility/4.9.5/scss/_sparta-project.scss';
$moduleNameSpace: dynamic-name-space;
@import '_config.scss';

$font-stack: $cnx-family;
$background: $color-brand-tertiary;

[data-sparta-container] {
  .spartaMessage {
    background-color: $background;
    border: $spacing-2 solid $color-black-alt;
    border-radius: $spacing-12;
    margin: $message-margin;
    display: flex;

    .spartaMessage-remind,
    .spartaMessage-neverShow {
      display: none;
    }

    p {
      font-family: $roboto-family;
      margin: 0;
      padding: $spacing-10 0 0 0;
      &:last-child {
        padding-bottom: 0;
      }
    }
    .spartaMessage-content {
      flex: 1;
      flex-flow: column;
      &.dismiss {
        padding-right: $spacing-16;
      }
      & > :first-child {
        &:focus {
          outline: 1px solid $color-royal-one;
          border-radius: 2px;
        }
      }
    }
    .spartaMessage-icon {
      float: left;
      .data-icon {
        position: absolute;
        display: inline-block;

      }
    }
    .spartaMessage-close-x {
      display: inline-block;
      height: 44px;
      width: 44px;
      background: transparent;
      padding: 0;
      margin: 0;
      outline: 0;
      border-color: transparent;
      background-size: 24px;
      background-repeat: no-repeat;
      background-position: center;
      background-image: icon(close, (color: $color-gray));

      &:hover,
      &:focus {
        border-color: $color-royal-one;
        background-image: icon(close, (color: $color-royal-one));
      }
    }
    .spartaMessage-title {
      font-family: $cnx-bold-family;
      padding-bottom: $title-padding;
      margin-top: 0;
    }
    .spartaMessage-list {
      margin-bottom: 0;
      padding: 0;
    }
    &.spartaMessage-border--none {
      border: 0;
    }
    &.error {
      border-color: $error;
      &.xsmall {
        .data-icon {
          background-image: icon(warning, (color: $error));
        }
        .spartaMessage-content {
          color: $error;
        }
      }
      .data-icon {
        background-image: icon(brandWarning, (color: $error));
      }
      .spartaMessage-title {
        color: $error;
      }
    }
    &.success {
      border-color: $success;
      &.xsmall {
        .data-icon {
          background-image: icon(success, (color: $success));
        }
      }
      .data-icon {
        background-image: icon(brandSuccess, (color: $success));
      }
    }
    &.info {
      &.xsmall {
        .data-icon {
          background-image: icon(info, (color: $info-xs));
        }
      }
      border-color: $info;
      .data-icon {
        background-image: icon(brandInfo, (color: $info));
      }
    }
    &.help {
      border-color: $info;
      .data-icon {
        background-image: icon(brandHelp, (color: $info));
      }
    }
    &.warning {
      border-color: $warning;
      &.xsmall {
        .data-icon {
          background-image: icon(bell, (color: $warning));
        }
      }
      .data-icon {
        background-image: icon(awareness, (color: $warning));
      }
    }
    &.large {

      font-size: $large-body-text-size--sm;
      line-height: $large-body-line-height--sm;
      .spartaMessage-content {
        padding: $content-padding--sm;
        & > :first-child {
          padding-top: 0;
          line-height: $large-title-line-height--sm;
        }
        & > :nth-child(2) {
          padding-top: 16px;
        }
      }
      p {
        font-size: $large-body-text-size--sm;
        padding: $spacing-12 0 0 0;
      }
      .spartaMessage-title {
        font-size: $large-title-text-size--sm;
      }
      .spartaMessage-icon {
        padding: $large-icon-padding--sm;
        flex: $large-icon-box-size--sm;
        .data-icon {
          width: $small-icon-size;
          height: $small-icon-size;
          background-size: $small-icon-size $small-icon-size;
        }
      }
      .spartaMessage-close-x {
        margin: $large-close-icon-margin--sm;
      }


      @media #{$medium-only} {
        font-size: $large-body-text-size--md;
        line-height: $large-body-line-height--md;
        .spartaMessage-content {
          padding: $content-padding--md;
          & > :first-child {
            line-height: $large-title-line-height--md;
          }
          p {
            font-size: $large-body-text-size--md;
          }
        }
        .spartaMessage-title {
          font-size: $large-title-text-size--md;
          margin-top: $spacing-4; 
        }
        .spartaMessage-icon {
          padding: $large-icon-padding--md;
          flex: $large-icon-box-size--md;
          .data-icon {
            width: $medium-icon-size;
            height: $medium-icon-size;
            background-size: $medium-icon-size $medium-icon-size;
          }
        }
        .spartaMessage-close-x {
          margin: $large-close-icon-margin--md;
        }
      }


      @media #{$large-up} {
        font-size: $large-body-text-size--lg;
        line-height: $large-body-line-height--lg;
        .spartaMessage-content {
          padding: $content-padding--lg;
          & > :first-child {
            line-height: $large-title-line-height--lg;
          }
          p {
            font-size: $large-body-text-size--lg;
          }
        }
        .spartaMessage-title {
          font-size: $large-title-text-size--lg;
          p {
            font-size: $large-title-text-size--lg;
          }
        }
        .spartaMessage-icon {
          padding: $large-icon-padding--lg;
          flex: $large-icon-box-size--lg;
          .data-icon {
            width: $large-icon-size;
            height: $large-icon-size;
            background-size: $large-icon-size $large-icon-size;
          }
        }
        .spartaMessage-close-x {
          margin: $large-close-icon-margin--lg;
        }
      }
    }
    &.medium {

      font-size: $medium-body-text-size--sm;
      line-height: $medium-body-line-height--sm;
      .spartaMessage-content {
        padding: $content-padding--sm;
        & > :first-child {
          padding-top: 0;
          line-height: $medium-title-line-height--sm;
        }
        & > :nth-child(2) {
          padding-top: 12px;
        }
      }
      p {
        font-size: $medium-body-text-size--sm;
        padding: $spacing-12 0 0 0;
      }
      .spartaMessage-title {
        font-size: $medium-title-text-size--sm;
      }
      .spartaMessage-icon {
        padding: $medium-icon-padding--sm;
        flex: $medium-icon-box-size--sm;
        .data-icon {
          width: $small-icon-size;
          height: $small-icon-size;
          background-size: $small-icon-size $small-icon-size;
        }
      }
      .spartaMessage-close-x {
        margin: $medium-close-icon-margin--sm;
      }


      @media #{$medium-only} {
        font-size: $large-body-text-size--md;
        line-height: $large-body-line-height--md;
        .spartaMessage-content {
          padding: $content-padding--md;
          & > :first-child {
            line-height: $large-title-line-height--md;
          }
          p {
            font-size: $large-body-text-size--md;
          }
        }
        .spartaMessage-title {
          font-size: $large-title-text-size--md;
          margin-top: $spacing-4; 
        }
        .spartaMessage-icon {
          padding: $large-icon-padding--md;
          flex: $large-icon-box-size--md;
          .data-icon {
            width: $medium-icon-size;
            height: $medium-icon-size;
            background-size: $medium-icon-size $medium-icon-size;
          }
        }
        .spartaMessage-close-x {
          margin: $medium-close-icon-margin--md;
        }
      }


      @media #{$large-up} {
        font-size: $medium-body-text-size--lg;
        line-height: $medium-body-line-height--lg;
        .spartaMessage-content {
          padding: $content-padding--md;
          & > :first-child {
            line-height: $medium-title-line-height--lg;
          }
          p {
            font-size: $medium-body-text-size--lg;
          }
        }
        .spartaMessage-title {
          font-size: $medium-title-text-size--lg;
          margin-top: 4px;
        }
        .spartaMessage-icon {
          padding: $medium-icon-padding--lg;
          flex: $medium-icon-box-size--lg;
          .data-icon {
            width: $medium-icon-size;
            height: $medium-icon-size;
            background-size: $medium-icon-size $medium-icon-size;
          }
        }
        .spartaMessage-close-x {
          margin: $medium-close-icon-margin--lg;
        }
      }
    }
    &.small {

      font-size: $small-body-text-size--sm;
      line-height: $small-body-line-height--sm;
      .spartaMessage-content {
        padding: $content-padding--sm;
        p {
          font-size: $small-body-text-size--sm;
        }
        &.dismiss {
          padding-right: 12px;
        }
        & > :first-child {
          padding-top: 0;
          line-height: $small-title-line-height;
        }
        & > :nth-child(2) {
          padding-top: 8px;
        }
      }
      p {
        padding: $spacing-8 0 0 0;
      }
      .spartaMessage-title {
        font-size: $small-title-text-size;
      }
      .spartaMessage-icon {
        padding: $small-icon-padding--sm;
        flex: $small-icon-box-size--sm;
        .data-icon {
          width: $small-icon-size;
          height: $small-icon-size;
          background-size: $small-icon-size $small-icon-size;
        }
      }
      .spartaMessage-close-x {
        margin: $small-close-icon-margin--sm;
      }


      @media #{$medium-only} {
        font-size: $small-body-text-size--md;
        line-height: $small-body-line-height--md;
        .spartaMessage-content {
          padding: $content-padding--sm;
          &.dismiss {
            padding-right: $spacing-16;
          }
        }
        .spartaMessage-icon {
          padding: $small-icon-padding--md;
          flex: $small-icon-box-size--md;
        }
        .spartaMessage-close-x {
          margin: $small-close-icon-margin--md;
        }
      }


      @media #{$large-up} {
        .spartaMessage-content {
          padding: $content-padding--sm;
          font-size: $small-body-text-size--lg;
          line-height: $small-body-line-height--lg;
          &.dismiss {
            padding-right: $spacing-16;
          }
        }
        .spartaMessage-icon {
          padding: $small-icon-padding--lg;
          flex: $small-icon-box-size--lg;
        }
        .spartaMessage-close-x {
          margin: $small-close-icon-margin--lg;
        }
      }
    }
    &.xsmall {

      border: 0;
      font-size: $xsmall-body-text-size--sm;
      line-height: $xsmall-body-line-height--sm;
      .spartaMessage-content {
        padding: $content-padding--xs;
        p {
          font-size: $xsmall-body-text-size--sm;
        }
      }
      p {
        padding: $xsmall-text-line-padding;
        &:last-child {
          padding-bottom: 0;
        }
      }
      .spartaMessage-icon {
        padding: $xsmall-icon-padding--sm;
        flex: $xsmall-icon-box-size--sm;
        .data-icon {
          width: $xsmall-icon-size;
          height: $xsmall-icon-size;
          background-size: $xsmall-icon-size $xsmall-icon-size;
        }
      }


      @media #{$medium-only} {
        font-size: $xsmall-body-text-size--md;
        line-height: $xsmall-body-line-height--md;
        .spartaMessage-content {
          padding: $content-padding--xs;
        }
        .spartaMessage-icon {
          padding: $xsmall-icon-padding--md;
          flex: $xsmall-icon-box-size--md;
        }
      }


      @media #{$large-up} {
        .spartaMessage-content {
          padding: $content-padding--xs;
          font-size: $xsmall-body-text-size--lg;
          line-height: $xsmall-body-line-height--lg;
        }
        .spartaMessage-icon {
          padding: $xsmall-icon-padding--lg;
          flex: $xsmall-icon-box-size--lg;
        }
      }
    }

  }


  .spa-ui-modal .spa-card__body .spartaMessage {
    .spartaMessage-content {
      font-family: $roboto-family;
    }

    .spartaMessage-title {
      font-family: $cnx-bold-family;
    }
  }


  .spartaMessage.error {
    &:focus {
      outline: 3px solid $color-royal-one;
      outline-offset: 2px;
    }

    &:has(.spartaMessage-content > :focus) {
      outline-color: transparent;
    }

      .spartaMessage-content button.spa-input-error-utility-button-show-hide {
      position: relative;
      top: 5px;
      padding: 0 $spacing-4;

        &:hover {
        outline: 1px solid $color-royal-one;
        border-radius: 2px;
      }

        .spa-icon {
        position: relative;
        top: -2px;
        left: -5px;
        vertical-align: middle;
        width: 20px;

          &:hover {
          border-color: transparent;
          text-decoration: none;
        }
      }
    }
  }
}
